<template>
  <div>
    <content-template
      :menuName="$t('sidebar.stakeholders')"
      :newButton="true"
      @moveToForm="moveToUserForm()"
    >
      <template #table-header>
        <span
          :class="`s-mb-30 ${isMobile ? 's-fullWidth' : 's-flex s-mt--65'}`"
        >
          <Input
            class="s-ml-auto"
            label=""
            :placeholder="$t('general.search')"
            v-model="request.search"
            @keyup="setTime()"
            @keydown="clearTime()"
            @keydown.tab="setTime()"
            @keyup.tab="clearTime()"
            @keypress.enter="getData()"
          />
        </span>
      </template>

      <template #table>
        <TableCustom
          :columns="columns"
          :request="request"
          :fetchData="fetchData"
          :data="rows"
          @setSortBy="setSortBy"
          @setShow="setShow"
          @getData="getData"
          @mouseleave="popup = null"
          @togglePopup="togglePopup"
        >
          <template #index="props">
            {{ curNumber(props.index) }}
          </template>

          <template #action="props">
            <div class="r-menu">
              <img
                class="r-isClickAble"
                :src="require('@/assets/icons/others/more.svg')"
               
              />
              <div
                class="r-menu-items"
                v-if="popup == curNumber(props.index)"
                @mouseleave="popup = -1"
              >
                <div
                  class="r-menu-item s-center-flex"
                  @click.prevent="setUpdate(props.row)"
                >
                  {{ $t("general.update") }}
                </div>
                <div
                  class="r-menu-item s-text-red s-center-flex"
                  @click.prevent="openDeleteDialog(true, props.row)"
                >
                  {{ $t("general.changeStatus") }}
                </div>
              </div>
            </div>
          </template>
        </TableCustom>
      </template>
    </content-template>

    <Modal v-if="dialogDelete" :title="$t('general.warning')">
      <template #modalContent>
        <p>{{ $t("users.modalStatus") }} {{ lang == 'id'? `status ${$t("sidebar.stakeholders").toLowerCase()}` : `stakeholder's status`}}?</p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button @click.prevent="onChangeStatus">
              {{ $t("general.yes") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import tableMixins from "@/mixins/tableMixins";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  mixins: [tableMixins, form],
  data() {
    return {
      columns: [
        {
          label: "No",
          fieldName: "index",
          isSortable: false,
          class: "r-table-firstColumn",
        },
        {
          label: "general.name",
          fieldName: "name",
          isSortable: true,
          class: "",
        },
        {
          label: "Email",
          fieldName: "email",
          isSortable: true,
          class: "",
        },
        {
          label: "general.institution",
          fieldName: "institution",
          isSortable: true,
          class: "",
        },
        {
          label: "general.position",
          fieldName: "position",
          isSortable: true,
          class: "",
        },
        {
          label: "general.phoneNumber",
          fieldName: "phone_number",
          isSortable: true,
          class: "",
        },
        {
          label: "general.category",
          fieldName: "category",
          isSortable: true,
          class: "",
        },

        {
          label: "Status",
          fieldName: "status",
          isSortable: true,
          class: "",
        },
        {
          label: "general.action",
          isSortable: false,
          fieldName: "action",
          class: "r-table-actionColumn",
        },
      ],
      current_id: "",
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    ...mapGetters({
      rows: "stakeholder/getStakeholder",
      role_type: "auth/getRole",
      request: "stakeholder/getRequest",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    dialogDelete() {
      return this.$store.getters["getModal"];
    },
  },
  methods: {
    ...mapActions({
      setStakeholder: "stakeholder/setStakeholderData",
      setModal: "setModal",
      delete_stakeholder: "admin/changeStatus",
    }),

    async initData() {
      await this.getData();
    },
    async onChangeStatus() {
      await this.delete_stakeholder(this.current_id);
      this.setModal(false);
      this.setStakeholder();
    },

    moveToUserForm() {
      this.$router.push(
        this.$translate({
          name: "New Users",
          params: { userType: "Stakeholder", inputType: "New" },
        })
      );
    },
    async getData(type = 0) {
      this.fetchData = true;
      if (type === 0) this.request.page = 1;
      else this.request.page += type;
      await this.setStakeholder();
      this.fetchData = false;
      // const response = this.getResponse("BRRD");
      // if (response.status !== 1) {
      //   this.showSnackbar({
      //     type: "error",
      //     text: response.msg,
      //   });
      // }
    },

    setUpdate(data) {
      this.$router.push(
        this.$translate({
          name: "Edit Users",
          params: {
            inputType: "Edit",
            userType: "Stakeholder",
            id: data.id,
          },
        })
      );
    },

    openDeleteDialog(state, data) {
      this.setModal(state);
      this.current_id = data.id;
    },
  },
};
</script>
